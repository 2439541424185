.sideBar {
    height: 100%;
    width: 250px;
    min-width: 250px;
    overflow-x: hidden;

    border-right: var(--light-background) 1px solid;

    background-color: var(--dark-background);

    display: flex;
    flex-direction: column;
}

.sideBar.fullSize {
    width: 100%;
    min-width: 100%;
    border-right: none;
}

.sideBarElement {
    text-decoration: none;
    font-size: 20px;
    height: 50px;

    display: flex;
    align-items: center;

    cursor: pointer;
    #border-radius: 0 20px 20px 0;
}


.sideBarElement:hover {
    background-color: var(--light-background);
}

.sideBarElement.active {
    border-left: var(--secondary) 5px solid;
}

.sideBarElementTitle {
    color: var(--secondary);

    margin-left: 20px;
}

.sideBarElement svg {
    fill: var(--secondary);
    color: var(--secondary);

    margin-left: 20px;
    margin-top: 2px
}

.activeBorder {
    height: 100%;
    width: 5px;
}

.activeBorder.active {
    width: 0;
    border-left: var(--secondary) 5px solid;
}



.sideBarDivider {
    text-decoration: none;
    font-size: 20px;
    height: 50px;

    display: flex;
    align-items: center;
}

.dividerBorder {
    width: 100%;
    border-bottom: darkcyan 1px solid;;
    margin-right: 20px;
}

.dividerTitle {
    color: darkcyan;

    font-weight: bolder;
    font-size: 0.9rem;

    margin-left: 20px;
    margin-right: 20px;
}