.sessionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 50px;
}

.sessionList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 50px;
}

.sessionItem {
    display: flex;
    flex-direction: column;

    background-color: var(--light-background);
    border-radius: 15px;

//border-bottom: 1px solid #e0e0e0;
}

.sessionItemContainer {
    padding: 0 20px;
}

.sessionEntry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.sessionEntryTitle {
    font-size: 1.2rem;
    font-weight: 500;
}

.sessionEntryValue {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--secondary);
}

.sessionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    width: 100%;
    border-radius: 15px;
    background-color: var(--primary);
}

.sessionButton:hover {
    cursor: pointer;
    opacity: 0.5;
}

.sessionEntryLogo {
    display: inline-flex;

    fill: white;

    margin-right: 10px;
    margin-bottom: -2px;
}