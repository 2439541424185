.projectList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    min-width: 400px;
    height: 300px;
    padding: 0 1rem;
    background-color: var(--light-background);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);

    transition: all 0.3s ease-in-out;
}