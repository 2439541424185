.stats {
    background-color: var(--light-background);

    padding: 20px 30px;
    border-radius: 30px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.stat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-bottom: 10px;
}

.statTitle {
    font-size: 1.2rem;
    font-weight: 600;

    margin-bottom: 10px;
}

.statDivider {
    margin-left: 5px;
    margin-right: 5px;
    height: 10px;
    
    border-left: solid 1px lightcoral;
}

.statNote {
    font-size: 0.8rem;
    font-weight: 600;

    margin-left: 10px;

    width: 30px;
    height: 30px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: var(--background);
}

/* Transactions */

.transactions {
    margin-bottom: 50px;
}

.transaction {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 20px;

    border-radius: 10px;

    margin-bottom: 10px;

    background-color: var(--light-background);
}

.transactionTitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.bigNote {
    font-size: 0.8rem;
    font-weight: 600;

    margin-left: 10px;

    width: 90px;
    height: 30px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 30px;
    background-color: var(--background);
}

.transactionDate {
    font-size: 0.95rem;
    font-weight: 600;

    margin-left: 10px;

    color: var(--tertiary);
}