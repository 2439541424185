.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 20px;
}

.form-input {
    margin-bottom: 20px;
}

.form-input input {
    width: 40vh;
    padding: 20px;
    border-radius: 5px;
    border: none;

    background-color: var(--light-background);
    color: #969696;
    font-size: 18px;
}

.form-input input:focus {
    outline: none;
}

.form-button {
    width: 40vh;
    border-radius: 5px;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    color: #061a10;
    font-size: 18px;
    padding: 20px;
}