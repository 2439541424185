* {
    color: white;
}

body {
    background-color: var(--background);
}

.content {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}
