button:focus {outline:0;}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 30px;
    height: 70px;

    //border-bottom: var(--light-background) 1px solid;
    background-color: var(--dark-background);
}

.logo {
    font-size: 30px;
    font-weight: 700;
}

.mainLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accountLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 180px;
}

a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;

    cursor: pointer;
}

a:hover {
    color: #969696;
}

.popOverButton {
    background-color: var(--dark-background);
    border: none;
    cursor: pointer;

    display: inline-flex;
    width: 40px;
    height: 40px;
}

.popOverButton:active {
    outline: none;
    border: none;
}

.popOverContainer {
    position: absolute;
    top: 80px;
    right: 10px;

    border-radius: 20px;
    width: 200px;

    padding: 40px 30px;

    background-color: var(--dark-background);
}

.popOverItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;

    cursor: pointer;
    font-size: 1.3rem;
    font-weight: bolder;
}

.popOverItem:hover {
    color: var(--primary);
}

.dashboardHeader {
    display: flex;
    align-items: center;

    height: 70px;
    margin: 0;

    //border-bottom: var(--light-background) 1px solid;
    background-color: var(--dark-background);
}