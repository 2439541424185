.gradient {
    --magic-rainbow-color-1: var(--primary);
    --magic-rainbow-color-2: var(--secondary);
    background: linear-gradient(
            170deg,
            var(--magic-rainbow-color-1),
            var(--magic-rainbow-color-2)
    );
    /* 🤯 */
    transition: --magic-rainbow-color-1 1000ms linear, --magic-rainbow-color-2
    1000ms linear;
}

.spin:hover:before {
    width: 16rem;
    height: 100%;
    opacity: 1;
    border-top: 0.125rem solid #ecf0f1;
    border-right: 0.125rem solid #ecf0f1;
    transition: width 150ms cubic-bezier(0.07, 0.62, 0.61, 1), height 75ms 150ms cubic-bezier(0.07, 0.62, 0.61, 1);
}

.spin:hover:after {
    width: 16rem;
    height: 100%;
    opacity: 1;
    border-bottom: 0.125rem solid #ecf0f1;
    border-left: 0.125rem solid #ecf0f1;
    transition: width 150ms 225ms cubic-bezier(0.07, 0.62, 0.61, 1), height 75ms 450ms cubic-bezier(0.07, 0.62, 0.61, 1), border-left 0ms 225ms linear;
}