.mediaContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-gap: 50px;

    margin-bottom: 50px;
}

.mediaItem {
    background-color: var(--light-background);

    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
}

.mediaThumbnail {

    z-index: 1;
    margin-top: -20px;
    width: 370px;
    height: 209px;

    border-radius: 20px;
    background-color: var(--dark-background);
}

.mediaThumbnailVideo {

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;

    margin-top: 1px;

    width: 370px;
    height: 209px;
    border-radius: 20px;
}

.mediaDuration {
    height: 20px;

    background-color: rgba(0, 0, 0, 0.65);
    padding: 2px 5px;
    border-radius: 5px;

    position: relative;
    top: 175px;
    left: 310px;
    z-index: 2;
}

.mediaItemMedia {
    transition: all 1s;
    position: relative;
}

.mediaItemMedia:hover img {
    transition: visibility 1s;
    visibility: hidden;
}

.mediaItemMedia:hover video {
    z-index: 1;
    opacity: 1;
    transition: opacity 1s;
}

.mediaItemMedia:hover span {
    transition: opacity 0.3s;
    opacity: 0;
}



.mediaInfoContainer {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.mediaTitle {
    font-size: 1.0rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.mediaViews {
    color: var(--tertiary);
}