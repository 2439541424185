.logoutButton {

    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 200px;
    height: 50px;
    background-color: #ff0000;

    border: none;
    border-radius: 5px;

    font-size: 16px;
    font-weight: 600;

    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
}

.logoutButton:hover {
    background-color: #ff3333;
    color: white;
}