.dashBoardContainer {
    height: calc(100% - 71px);
    display: flex;
}

.dashBoardContainer.fullSideBar {
    height: calc(100% - 71px);
    display: flex;

    flex-direction: column;
}

/*@media (max-width: 1000px) {
    .dashBoardContainer {
        height: calc(100% + 15px);
    }
}*/

.dashBoardContent {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;

    overflow: auto;
}

.center {
    display: flex;
    justify-content: center;
}